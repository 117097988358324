import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import FeatureSectionOneCol from "components/FeaturesSection/FeatureSectionOneCol";
import FeatureSectionTwoCol from "components/FeaturesSection/FeatureSectionTwoCol";
import Image from "components/Image";
import SectionHero from "components/SectionHero/SectionHero";
import LogoSection from "components/SectionLogo/LogoSection";
import SectionTextImage from "components/SectionTextImages/SectionTextImage";

import SectionSliderServices from "components/SwiperCarousel/SectionSliderServices";

import { Feature } from "data/types";

import rightImg from "images/finvue-homepage-hero-section-right.png";
import React from "react";
// images featuresDataSample
import FaqSection from "components/FaqSections/FaqSection";
import featureLeft from "images/finvue/allinone.png";
import featureRight from "images/finvue/availablemobile.png";
import oneFeatureCenter from "images/one-section-cards-center.png";
import oneFeatureRight from "images/one-section-debit-card-right.png";
// icons statistics
import StatisticItem from "components/FinvueComponents/StatisticItems";
import globeIcon from "images/icons/Globe-icon.svg";
import customersIcon from "images/icons/customers-icon.svg";
import depositsIcon from "images/icons/deposits-icon.svg";
// Carousel Images
import carouselImage1 from "images/finvue/services/_0000_schedule-img.png";
import carouselImage2 from "images/finvue/services/_0001_cards-img.png";
import carouselImage3 from "images/finvue/services/_0002_Batchpayments-img.png";
import carouselImage4 from "images/finvue/services/_0003_payrol-img.png";
import carouselImage5 from "images/finvue/services/_0004_pricing-img.png";

// video
import StatCard from "app/about/StatCard";
import GlobalStats from "components/FinvueComponents/ExperiencesSections/GlobalStats";
import FinVuePlans from "components/FinvueComponents/FinVuePlans/FinVuePlans";
import OfficeCard from "components/FinvueComponents/OfficesSections/OfficeCard";
import OfficeLocations from "components/FinvueComponents/OfficesSections/OfficeLocations";
import PricingPlans from "components/FinvueComponents/PricingSection/PricingPlans";
import StatisticItems from "components/FinvueComponents/StatisticItems";
import AnimatedStatCard from "components/FinvueComponents/Stats/AnimatedStatCard";
import Heading from "components/Heading/Heading";
import Heading2 from "components/Heading/Heading2";
import videoSrc from "images/finvue/animation.webm";

// SEO
import SEO from "components/SEO";

// Statistics
const statisticsData = [
  {
    iconSrc: globeIcon,
    text: "160+ countries and regions supported",
  },
  {
    iconSrc: customersIcon,
    text: "40+ million global customers and counting",
  },
  {
    iconSrc: depositsIcon,
    text: "Trusted with $15+ billion in deposits",
  },
];
//Adding features
const featuresDataSample: Feature[] = [
  {
    id: 1,
    title: "Make and take payments, all in one place",
    subtitle: "Accept payments easily and get fast access to your funds.",
    image: featureLeft,
    link: "/services",
    color: "light",
    imgPosition: "right",
  },
  {
    id: 2,
    title: "Also available on mobile",
    subtitle: "Easily and securely access your FinVue Business account from your phone, with our mobile app",
    image: featureRight,
    link: "https://apps.apple.com/pl/app/finvue/id6476549366",
    color: "dark",
    imgPosition: "right",
  },
];
const featuresDataSampleOneCol: Feature[] = [
  {
    id: 1,
    title: "Make and take payments, all in one place",
    subtitle: "Accept payments easily worldwide and get fast access to your funds",
    image: oneFeatureRight,
    link: "#",
    color: "dark",
    imgPosition: "right",
  },
  {
    id: 2,
    title: "Smart cards for smart business",
    subtitle: "Empower yourself and your team with physical and virtual company debit cards",
    image: oneFeatureCenter,
    link: "#",
    color: "light",
    imgPosition: "center",
  },
];
const carouselItems = [
  {
    id: 1,
    title: "Scheduling Payments",
    subtitle: "Subtitle 1",
    image: carouselImage1,
    link: "#",
  },
  {
    id: 2,
    title: "Physical and virtual cards",
    subtitle: "Subtitle 2",
    image: carouselImage2,
    link: "#",
  },
  {
    id: 3,
    title: "Batch Payments",
    subtitle: "Subtitle 3",
    image: carouselImage3,
    link: "#",
  },
  {
    id: 4,
    title: "Payroll as a Service",
    subtitle: "Subtitle 4",
    image: carouselImage4,
    link: "#",
  },
  {
    id: 5,
    title: "Invoicing",
    subtitle: "Subtitle 5",
    image: carouselImage5,
    link: "#",
  },
  {
    id: 1,
    title: "Scheduling Payments",
    subtitle: "Subtitle 1",
    image: carouselImage1,
    link: "#",
  },
  {
    id: 2,
    title: "Physical and virtual cards",
    subtitle: "Subtitle 2",
    image: carouselImage2,
    link: "#",
  },
  {
    id: 3,
    title: "Batch Payments",
    subtitle: "Subtitle 3",
    image: carouselImage3,
    link: "#",
  },
  {
    id: 4,
    title: "Payroll as a Service",
    subtitle: "Subtitle 4",
    image: carouselImage4,
    link: "#",
  },
  {
    id: 5,
    title: "Invoicing",
    subtitle: "Subtitle 5",
    image: carouselImage5,
    link: "#",
  },
];
const statistics = [
  { value: 90, prefix: "£", suffix: "M+", label: "Money flow processed in 2024" },
  { value: 120, prefix: "", suffix: "K+", label: "Transactions completed in 2024" },
  { value: 2, prefix: "£", suffix: "M+", label: "Trusted in safeguarding accounts" },
];
const PageHome = () => {
  // Logos
  const logos = ["wise", "deloitte", "drift", "rbs", "lenovo", "intel"];

  return (
    <div className="nc-PageHome relative -mt-20 overflow-hidden">
      <SEO
        title="FinVue - The Account Built For Businesses"
        description="Streamline operations and save time with our innovative, user-friendly payment management solutions tailored for businesses of all sizes."
        keywords="Scheduling Payments, Physical and Virtual Cards, Batch Payments. Payroll as a Service, Invoicing"
      />
      <div className="bg-custom-gradient rounded-b-3xl">
        <div className="container">
          <SectionHero
            rightImg={rightImg}
            className="pt-6"
            heading={
              <div className="text-3xl lg:text-6xl text-slate-50 font-extrabold">
                The Account Built <span className="!text-blue-500 block">For Businesses</span>
              </div>
            }
            btnTextPrimary="Join Now"
            btnPrimaryUrl="/sign-up"
            btnTextSecondary="Contact Sales"
            btnSecondaryUrl="/contact"
            subHeading="Streamline operations and save time with our innovative, user-friendly payment management solutions tailored for businesses of all sizes."
            subHeadingColor="text-slate-200"
          />
        </div>
      </div>
      <section className="relative py-16 md:py-16">
        <SectionSliderServices
          heading={
            <h3 className="text-2xl md:text-5xl font-semibold">
              Everything you need to run <span className="text-blue-700">your business in one place</span>
            </h3>
          }
          subHeading="From the second you open your account, get everything you need to drive your business forward."
          items={carouselItems}
        />
      </section>
      <section className="container pt-10 md:pt-18">
        <Heading isCenter desc="">
          <h3 className="text-slate-950 text-3xl md:text-5xl">
            Let’s run the <span className="text-blue-700">numbers</span>
          </h3>
        </Heading>
        <div className="flex gap-5 max-md:flex-col">
          {statistics.map((stat, index) => (
            <div key={index} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
              <AnimatedStatCard value={stat.value} prefix={stat.prefix} suffix={stat.suffix} label={stat.label} />
            </div>
          ))}
        </div>
      </section>

      <div className="container relative">
        {/* <div className="video-container bg-transparent">
          <video controls className="video-player" playsInline>
            <source src={videoSrc} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div> */}

        {/* BEGIN Features sections */}
        <div className="relative pt-16">
          <div>
            <Heading isCenter desc="Keep costs down and productivity high">
              <h3 className="text-2xl md:text-5xl font-semibold">
                More than <span className="text-blue-700 block">just the essentials</span>
              </h3>
            </Heading>
          </div>
          <FeatureSectionTwoCol features={featuresDataSample} />
        </div>

        {/* FAQ */}
        <div className="relative py-16 pb-4">
          <FaqSection dataIds={[1, 2, 3, 4]} />
        </div>
      </div>
      {/* <section className="-mt-12">
        <StatisticItems />
      </section> */}

      <section>
        <FinVuePlans />
      </section>
    </div>
  );
};

export default PageHome;

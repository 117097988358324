import Logo from "components/Logo/Logo";
import SocialsList from "components/SocialsList/SocialsList";
import gbFlagImg from "images/finvue/gb-flag.png";
import livingWagesImg from "images/finvue/Living_Wage_Foundation_logo.svg";
import React from "react";

const links = [
  { href: "/legal", label: "Privacy Policy" },
  { href: "/legal", label: "Cookie Policy" },
  { href: "/legal", label: "Disclaimer" },
  { href: "/legal", label: "Terms & Conditions" },
];

const Footer: React.FC = () => {
  return (
    <footer className="nc-Footer relative py-10 lg:py-20 border-neutral-200 dark:border-neutral-700 bg-blue-950 text-white">
      <div className="container px-4 mx-auto">
        <div className="mx-auto pb-8 mb-2 border-b border-gray-700 flex items-center justify-between">
          <div className="flex items-center">
            <Logo className="w-32 h-auto" />
          </div>
          <div className="flex items-center space-x-8">
            <SocialsList className="flex items-center space-x-4" />
          </div>
        </div>
        <div className="relative">
          <div className="flex py-4 lg:flex-wrap overflow-x-auto whitespace-nowrap text-sm space-x-4 pr-6">
            <div className="text-gray-300 mr-8 md:mr-0 flex gap-2 items-center hover:text-gray-200">
              <img src={gbFlagImg} alt="United Kingdom Flag" />
              <a className="border-r border-slate-600 pr-4" href="##">
                United Kingdom
              </a>
            </div>
            {links.map((item, index) => (
              <div key={index} className="text-gray-300 flex-shrink-0 py-2 hover:text-gray-200">
                <a href={item.href}>{item.label}</a>
              </div>
            ))}
          </div>
          {/* Fading effect */}
          <div className="absolute md:hidden top-0 right-0 h-full w-16 bg-gradient-to-l from-blue-950 to-transparent pointer-events-none"></div>
        </div>
        <div className="text-left mt-4 text-gray-500 text-sm">
          <p className="py-2 text-xs">
            UK Programme - Taupe Global Systems Ltd trading as Finvue is a payment programme of Orenda Financial Services Limited (UK). Orenda Financial Services have an agreement with Monavate Ltd for card issuance and related payment services.
            Monavate Ltd is a limited company registered in England and Wales with registration number 12472532. Our registered office is at The Officers Mess Business Centre, Royston Road, Duxford, Cambridge, England, CB22 4QH. Monavate Ltd is
            authorised and regulated by the Financial Conduct Authority and registered with Firm Reference Number 901097. Monavate Ltd are regulated and authorised to issue cards and electronic money under the Taupe Global Systems Ltd trading as
            Finvue payment programme.
          </p>
          <p className="py-2 text-xs">Taupe Global Systems Ltd is registered with the Information Commissioner’s Office, with registration number: ZB672320.</p>
          <p className="text-xs">
            © Copyright 2024, All Rights Reserved by Taupe Global Systems Ltd.
            <br />
            Taupe Global Systems Ltd trading as FinVue. Registered Office: Level 18, 40 Bank Street, Canary Wharf, London, England, E14 5NR <br />
            Registered Number 14978273 Registered in England and Wales.
          </p>
          <p className="py-4">
            You can read more about our Terms and Policies by clicking{" "}
            <a href="/legal" className="underline hover:text-white">
              here
            </a>
            .
          </p>
          <div>
            <img className="w-16" src={livingWagesImg} title="Living Wages Foundation" alt="Living Wages Foundation" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
